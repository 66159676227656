/**
  This component wraps the entire page in itself 
  Here we load header, footer and main content. 
  Only change this file if you are changing the entire site
  Else use if-else statements (like if slug = "something") 
  if you for some reason need to edit individual pages in this file 
  ...But there is probably a better way to do that than to edit this file 
 */
import React from "react"
/* 
 PropTypes allows us to use TypeScript intellisense 
 Only works on packages with prop types, you can often download prop types individually for packages
*/ 
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../style/layout.scss"

const Layout = ({ children }) => {
  // In Gatsby all queries outside of a page (like in this component) must be static queries 

  /* TODO:  Move to footer or wherever this data is needed
  const data = useStaticQuery(graphql`
  query CompanyInfo {
    sanityCompanyInfo {
      name
      email
      country
      city
      address1
      zipCode
    }
  }
  `)
const headerData = data.sanityCompanyInfo 
*/ 

  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.funka.com/">Funka</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
